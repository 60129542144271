:root {
  /* https://www.happyhues.co/palettes/14 */
  --grid: 10px;
  --background: #fffffe;
  --headline: #272343;
  --paragraph: #2d334a;
  --button: #ffd803;
  --button-text: #272343;
  --illustration-stroke: #272343;
  --illustration-main: #fffffe;
  --illustration-highlight: #ffd803;
  --illustration-secondary: #e3f6f5;
  --illustration-tertiary: #bae8e8;
}

body {
  margin: 0;
  font-family: proxima-nova, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--paragraph);
  font-size: 1.25rem;
  color: var(--paragraph);
}

h1,
h2,
h3,
h4 {
  color: var(--headline);
}

h1 {
  font-weight: 700;
}

h2 {
  font-weight: 500;
}

h3 {
  font-weight: 300;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
